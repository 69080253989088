
import Vue from "vue";

import {
  authMapActions,
  authMapGetters,
  authMapMutations
} from "@/store/modules/auth";
import { validateFields } from "@/helpers";
import * as types from "@/store/mutation-types";
import { logoPath } from "@/helpers/generalHelpers";

interface ILoginData {
  verifyingCode: boolean;
  error: string;
  mfaCode: string;
}

export default Vue.extend({
  name: "LoginAuthenticateVerify",
  data(): ILoginData {
    return {
      verifyingCode: false,
      error: "",
      mfaCode: ""
    };
  },
  watch: {
    isLoggedIn: function(loggedIn) {
      this.$nextTick(function() {
        if (loggedIn) {
          this.$router.push("/").catch(() => {});
          this.$gtag.event("login", { method: "mfa" });
        }
      });
    }
  },
  methods: {
    ...authMapActions(["verifyMfa"]),
    ...authMapMutations([types.LOGOUT]),
    getLogoPath() {
      return logoPath();
    },
    async mfaVerify(): Promise<void> {
      this.error = "";
      this.verifyingCode = true;
      // const loading = this.$loading({
      //   lock: true
      // });
      try {
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (isValid) {
          const { mfaCode } = this;
          await this.verifyMfa(mfaCode);
        }
      } catch (e) {
        this.error = e.message;
        this.$appNotifyError(this.error);
        this.$bugSnagClient.notify(e);
      } finally {
        // loading.close();
        this.verifyingCode = false;
      }
    }
  },
  computed: {
    ...authMapGetters(["isLoggedIn"]),
    mfaAccount: function(): string {
      return this.$route.params.mfaAccount || "device";
    },
    mfaMethod: function(): string {
      const mfaMethod = this.$route.params.mfaMethod;
      if (mfaMethod && mfaMethod.indexOf("Primary") > -1) {
        return "Primary Phone";
      }
      if (mfaMethod && mfaMethod.indexOf("Secondary") > -1) {
        return "Secondary Phone";
      }
      return ``;
    }
  },
  beforeDestroy() {
    localStorage.removeItem("auth_mfa_verify");
    this.LOGOUT(false);
  }
});
